<template>
  <div>
    <div class="sm:mb-4 mb-0 text-lg black95 bg-white py-8 border-b border-t px-4">收款人列表</div>
    <div class=" sm:p-12 p-2 bg-white">
      <div class=" flex justify-end">
        <a-button  type="primary" icon="user-add" class="editable-add-btn" @click="handleRouteAdd">
          添加收款人
        </a-button>
      </div>
      <a-table class="mt-4" :loading="dataLoding" bordered :data-source="dataSource" :columns="columns" rowKey="id">
        <!-- <template slot="name" slot-scope="text, record">
          <EditableCell :text="text" @change="onCellChange(record.key, 'name', $event)" />
        </template> -->
        <span slot="customNameTitle"><a-icon type="user" /> 名字</span>
        <template slot="currency" slot-scope="text,record">
          <div class="flex items-center">
            <span class=" font-600 black95">{{ record.currency }}</span>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="flex justify-evenly">
            <a-button type="primary" @click="handleToPay(record.currency,record.beneficiary_id)">支付</a-button>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getCCBeneficiariesList } from '@/api/common'
import { mapState, mapGetters } from 'vuex'

export default {
  created () {
    this.dataLoding = true
    getCCBeneficiariesList({ customer_id: this.customer.id }).then(res => {
      this.dataSource = res.beneficiaries
      this.dataLoding = false
    }).catch(error => {
      console.log(error)
      this.dataLoading = false
    })
  },
  data () {
    return {
      dataLoding: false,
      dataSource: [
      ],
      count: 2,
      columns: [
        {
          key: 'name',
          slots: { title: 'customNameTitle' },
          dataIndex: 'bank_account_holder_name'
        },
        {
          key: 'bank_card',
          title: '银行卡号',
          dataIndex: 'account_number'
        },
        {
          key: 'account_type',
          title: '户头类型',
          dataIndex: 'beneficiary_type'
        },
        {
          key: 'account_currency',
          title: '货币类型',
          dataIndex: 'currency',
          scopedSlots: { customRender: 'currency' }
        },
        {
          key: 'bic_swift',
          title: 'BIC Swift',
          dataIndex: 'bic_swift'
        },
        {
          key: 'action',
          title: 'Actions',
          dataIndex: 'id',
          width: '260px',
          scopedSlots: { customRender: 'operation' }
        }
      ]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log('beforeRouteEnter')
      if (!vm.isCCactive) {
        vm.$message.error('Please activate your account first')
        next({ name: 'UserDash' })
      }
    })
  },

  methods: {
    onCellChange (key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
      }
    },
    handleToPay (currency, beneficiaryId) {
      if (!currency) {
        this.$message.error('Please add currency first')
        return
      }
      this.$router.push({ path: '/user/payment-create', query: { currency, beneficiary_id: beneficiaryId } })
    },
    handleRouteAdd () {
      this.$router.push({ name: 'BeneficiaryAddStepOne' })
    }
  },
  computed: {
    ...mapState('cc', ['customer']),
    ...mapGetters('cc', ['isCCactive'])
  }
}
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
